
import { apiPackageDel, apiPackageLists, apiPackageGets } from '@/api/goods'
import { RequestPaging } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
@Component({
    components: {
        LsDialog,
        LsPagination,
        ExportData
    }
})
export default class Supplier extends Vue {
    queryObj = {
        name: ''
    }
    pager = new RequestPaging()
    apiPackageLists = apiPackageLists
    getList(page?: number): void {
        page && (this.pager.page = page)
        this.pager.request({
            callback: apiPackageLists,
            params: {
                ...this.queryObj
            }
        })
    }
    handleReset() {
        this.queryObj = {
            name: ''
        }
        this.getList()
    }

    handleDelete(id: number) {
        apiPackageDel(id).then(() => {
            this.getList()
        })
    }
	
	getGzlist(){
		const loading = this.$loading({
			lock: true,
			text: '拉取套餐中...',
			spinner: 'el-icon-loading',
			background: 'rgba(0, 0, 0, 0.7)',
		});
		apiPackageGets({}).then(()=>{
			loading.close();
			this.getList()
		}).catch(()=>{
			loading.close();
			this.getList()
		})
	}

    created() {
        this.getList()
    }
}
